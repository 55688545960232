<template>
  <base-view id="whats-covered">
    <view-header title="What's Covered" image="bg-whats-covered.jpg">
      Wondering what you can use your funds on? We've compiled a comprehensive
      and up-to-date list of services and products, and under what circumstances
      they are eligible.
    </view-header>
    <view-section class="section1">
      <div
        id="whats-covered-searchbar"
        class="absolute left-0 right-0 flex items-center w-auto h-12 max-w-3xl mx-auto my-0 overflow-hidden bg-white border border-white rounded shadow-lg -top-6 focus-within:border-primary-light"
      >
        <span
          class="py-0 m-0 mx-4 text-xl font-semibold text-neutral-darker flaticon-search-1"
        />
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search"
          v-model="searchValue"
          class="flex-1 w-40 px-0 py-2 mr-2 text-2xl border-0 outline-none"
        />
        <div
          class="absolute right-0 flex flex-row-reverse items-center h-full p-0 pl-4 overflow-scroll transition-all duration-500 bg-white border-l filter-box w-14 whitespace-nowrap"
          :class="{ 'w-full': showFilters }"
        >
          <span
            @click="showFilters = !showFilters"
            class="py-0 m-0 mx-4 text-xl text-neutral-darker cursor-pointer filter-icon"
            :class="{
              'flaticon-null': !showFilters,
              'flaticon-settings-close': showFilters
            }"
          />
          <input
            type="checkbox"
            name="Eligible"
            id="checkbox-e"
            v-model="checkedEligible"
            class="my-0 ml-1 mr-4"
          />
          <label for="checkbox-e" class="inline-block">Eligible</label>
          <input
            type="checkbox"
            name="Potentially Eligible"
            id="checkbox-p"
            v-model="checkedPotentiallyEligible"
            class="my-0 ml-1 mr-4"
          />
          <label for="checkbox-p" class="inline-block"
            >Potentially Eligible</label
          >
          <input
            type="checkbox"
            name="Not Eligible"
            id="checkbox-n"
            v-model="checkedNotEligible"
            class="my-0 ml-1 mr-4"
          />
          <label for="checkbox-n" class="inline-block">Not Eligible</label>
        </div>
      </div>

      <Table
        :searchValue="searchValue"
        :searchEligible="checkedEligible"
        :searchPotentiallyEligible="checkedPotentiallyEligible"
        :searchNotEligible="checkedNotEligible"
        class="pt-32"
      />
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import Table from "./components/Table.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    Table: Table
  },
  data() {
    return {
      searchValue: "",
      showFilters: false,
      checkedEligible: true,
      checkedPotentiallyEligible: true,
      checkedNotEligible: true,
      windowWidth: window.innerWidth
    };
  },
  methods: {
    handleScroll() {
      let searchbar = document.getElementById("whats-covered-searchbar");
      let table = document.getElementById("eligibility-table");
      let tableRect = table.getBoundingClientRect();

      if (tableRect.top < 36) {
        searchbar.style.position = "fixed";
        searchbar.style.top = "12px";
      } else {
        searchbar.style.position = "absolute";
        searchbar.style.top = "-1.5rem";
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;

      let searchbar = document.getElementById("whats-covered-searchbar");
      let table = document.getElementById("eligibility-table");
      let tableRect = table.getBoundingClientRect();

      if (this.isMobile) {
        searchbar.style.marginLeft = "1rem";
        searchbar.style.marginRight = "1rem";
      } else {
        searchbar.style.marginLeft = "auto";
        searchbar.style.marginRight = "auto";
      }
    }
  },
  computed: {
    isMobile: function() {
      return this.windowWidth <= 800;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style scoped>
.section1 {
  position: relative;
  padding: 0 0 4rem 0;
}

.filter-box::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  height: 0px;
  background: transparent; /* Optional: just make scrollbar invisible */
}
</style>
